module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Specialistläkargruppen","short_name":"SLG","start_url":"/","background_color":"#e56343","theme_color":"#e56343","display":"standalone","icon":"src/images/hearts-square.png"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-44043247-1","cookieName":"CookieConsent"},"googleTagManager":{"trackingId":"GTM-MZNKML6","cookieName":"CookieConsent","dataLayerName":"dataLayer"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
